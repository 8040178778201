import { lazy, Suspense, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Stack } from 'react-bootstrap';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@components/Button/IconButton';
import useAuth from '@hooks/useAuth';

import CancelConfirmDialog from './components/CancelConfirmDialog/CancelConfirmDialog';
import './RequisitionCreate.scss';
import { Campaign } from '@customTypes/campaign';

const RequisitionJSONSchemaForm = lazy(
  () => import('./components/RequisitionJSONSchemaForm/RequisitionJSONSchemaForm'),
);
const OrderingOptionSelect = lazy(() => import('./components/OrderingOptionSelect/OrderingOptionSelect'));

export default function RequisitionCreate() {
  const navigate = useNavigate();
  const { hasPartnershipOrdering } = useAuth();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null | undefined>(undefined);
  const isOnCampaignSelectScreen = useMemo(
    () => selectedCampaign === undefined && hasPartnershipOrdering,
    [selectedCampaign, hasPartnershipOrdering],
  );
  const subtitle = useMemo(() => {
    if (isOnCampaignSelectScreen) return 'Select an option to proceed.';

    // eslint-disable-next-line max-len
    return 'Fill out the form in each step as provided. Use the Next button to continue to the next step, and the Submit button when all steps are completed. Some product selections may not require additional information.';
  }, [isOnCampaignSelectScreen]);

  const moveToRequisitions = () => {
    navigate('/requisitions');
  };

  const onOrderingOptionSelect = (campaign: Campaign | null) => {
    setSelectedCampaign(campaign);
  };

  return (
    <Suspense>
      <Stack gap={4} className='layout-single-col-py create-requisition-container' data-testid='newOrderPage'>
        <Breadcrumb listProps={{ className: 'mb-0' }}>
          <Breadcrumb.Item onClick={moveToRequisitions}>Orders and Reports</Breadcrumb.Item>
          <Breadcrumb.Item active>New Order</Breadcrumb.Item>
        </Breadcrumb>
        <Card as='section' className='text-start px-4 pb-4 pt-2 box-shadow-50 position-static'>
          <Card.Title as='h1' className='mt-md-1 fw-bold page-title'>
            {isOnCampaignSelectScreen ? 'Your ordering options' : 'New Order'}
          </Card.Title>
          <Card.Subtitle className='mt-md-1 fs-body fw-normal text-second mb-0 page-subtitle' as='p'>
            {subtitle}
          </Card.Subtitle>
          <Card.Body className='p-0 pt-4'>
            {isOnCampaignSelectScreen ? (
              <OrderingOptionSelect onSelect={onOrderingOptionSelect} />
            ) : (
              <RequisitionJSONSchemaForm selectedCampaign={selectedCampaign} />
            )}
          </Card.Body>
          <Card.Footer className='bg-white p-0 pt-3 mt-4 border-top'>
            <IconButton
              variant='outline-dark'
              icon={faArrowLeftLong}
              onClick={() => setShowConfirmDialog(true)}
              data-testid='cancelAndReturnBtn'
            >
              Cancel and return
            </IconButton>
          </Card.Footer>
        </Card>
      </Stack>
      {showConfirmDialog && (
        <CancelConfirmDialog onClose={() => setShowConfirmDialog(false)} onConfirm={moveToRequisitions} />
      )}
    </Suspense>
  );
}
