import { useContext, useEffect, useState } from 'react';

import { Requisition } from '@customTypes/requisition';
import { Context } from '@store/store';

interface IUsePatientDetail {
  requisitions: Requisition[];
}

export default function usePatientDetail(patientId?: string): IUsePatientDetail {
  const [state] = useContext(Context);

  const [requisitions, setRequisitions] = useState<Requisition[]>([]);

  useEffect(() => {
    const requisitions = state.requisitions?.filter(item => item.individual_id === patientId);

    setRequisitions(requisitions ?? []);
  }, [patientId, state.requisitions]);

  return {
    requisitions,
  };
}
