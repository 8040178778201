import AccountReview from '@pages/account-review/AccountReview';
import ErrorPage from '@pages/error/errorPage';
import Login from '@pages/login/login';
import PatientDetail from '@pages/patientDetail/PatientDetail';
import RequisitionsPage from '@pages/requisitions/Requisitions';
import RequisitionComplete from '@pages/requisitionComplete/RequisitionComplete';
import RequisitionCreate from '@pages/requisitionCreate/RequisitionCreate';
import RequisitionCreatePageContainer from '@pages/requisitionCreate/RequisitionCreatePageContainer';
import Signup from '@pages/signup/Signup';
import PageContainer from '@components/Layout/PageContainer/PageContainer';

export type RoutePath =
  | '/requisitions'
  | '/'
  | '/login'
  | '/signup'
  | '/account-review'
  | '/error'
  | '/requisitions/create'
  | '/requisitions/complete'
  | '/patients/:id'
  | '*';

export interface PageRoute {
  path: RoutePath;
  element: React.ReactNode;
  PageContainer?: ({ children }: { children: React.ReactNode }) => React.ReactElement;
  newLayout?: boolean;
  keepOnError?: boolean; // Do not navigate to a generic error page on API error
  hide?: boolean;
  private?: boolean; // True for protected routes
  onlyAuth?: boolean; // True for auth pages
}

const routes: PageRoute[] = [
  {
    path: '/requisitions',
    element: <RequisitionsPage />,
    newLayout: true,
    keepOnError: true,
    private: true,
  },
  {
    path: '/requisitions/create',
    element: <RequisitionCreate />,
    PageContainer: RequisitionCreatePageContainer,
    newLayout: true,
    keepOnError: true,
    private: true,
  },
  {
    path: '/requisitions/complete',
    element: <RequisitionComplete />,
    newLayout: true,
    private: true,
  },
  {
    path: '/patients/:id',
    element: <PatientDetail />,
    PageContainer,
    newLayout: true,
    private: true,
  },
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/login',
    element: <Login />,
    onlyAuth: true,
  },
  {
    path: '/signup',
    element: <Signup />,
    onlyAuth: true,
    newLayout: true,
    keepOnError: true,
  },
  {
    path: '/account-review',
    element: <AccountReview />,
    onlyAuth: true,
    newLayout: true,
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
];

export default routes;
