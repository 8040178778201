import { Button, Modal } from 'react-bootstrap';

interface CancelConfirmDialogProps {
  onClose: () => void;
  onConfirm: () => void;
}

const CancelConfirmDialog = ({ onClose, onConfirm }: CancelConfirmDialogProps) => {
  return (
    <Modal
      show
      onHide={onClose}
      id='cancel-return-confirm-dialog'
      aria-labelledby='cancel-return-modal-title'
      data-testid='requisitionCancelConfirmDialog'
    >
      <Modal.Header closeButton>
        <Modal.Title as='h1' id='cancel-return-modal-title' className='fs-5 fw-medium lh-sm'>
          Cancel and Return
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='mb-0'>
          Do you want to return to the Orders and Reports page? Your order form progress will be lost.
        </p>
      </Modal.Body>
      <Modal.Footer className='gap-3'>
        <Button variant='outline-primary' onClick={onConfirm} className='flex-grow-1'>
          Yes
        </Button>
        <Button
          variant='primary'
          onClick={onClose}
          className='flex-grow-1'
          data-testid='createRequisitionCloseConfirmBtn'
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelConfirmDialog;
