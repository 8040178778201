import { Stack } from 'react-bootstrap';

import Placeholder from '@components/Placeholder/Placeholder';

import './PatientDetailSkeleton.scss';

const PatientDetailSkeleton = () => {
  return (
    <Stack gap={4} className='patient-detail-skeleton patient-detail-content'>
      <div className='d-flex align-items-center gap-2 breadcrumbs'>
        <Placeholder size='lg' />
        <Placeholder size='lg' />
      </div>

      <section className='box-shadow-50 bg-white skeleton-section'>
        <Placeholder size='2xl' className='w-100' />
        <div className='d-flex gap-3'>
          <Placeholder size='lg' className='w-180' />
          <Placeholder size='lg' className='w-180' />
        </div>
      </section>

      <section className='box-shadow-50 bg-white expandable-section skeleton'>
        <div className='d-flex align-items-center section-header'>
          <Placeholder size='2xl' className='w-100' />
        </div>
        <div className='d-flex flex-column gap-4 show expandable-content'>
          <div className='w-100 p-2 bg-light rounded-3 d-flex flex-column gap-2'>
            <Placeholder size='sm' className='w-180' />
            <div className='d-flex flex-column flex-md-row gap-2 gap-md-4'>
              <Placeholder size='3xl' className='w-360' />
              <Placeholder size='3xl' className='w-360' />
            </div>
          </div>
          <Placeholder size='xs' className='w-100' />
        </div>
      </section>

      <section className='box-shadow-50 bg-white expandable-section skeleton'>
        <div className='d-flex align-items-center section-header'>
          <Placeholder size='2xl' className='w-100' />
        </div>
        <div className='d-flex flex-column flex-md-row gap-4 show expandable-content'>
          <div className='p-2 bg-light rounded-3 d-flex flex-column gap-3 card-2'>
            <Placeholder size='sm' className='w-50' />
            <Placeholder size='3xl' className='w-100' />
          </div>
          <div className='p-2 bg-light rounded-3 d-flex flex-column gap-3 card-2'>
            <Placeholder size='sm' className='w-50' />
            <Placeholder size='3xl' className='w-100' />
          </div>
        </div>
      </section>
    </Stack>
  );
};

export default PatientDetailSkeleton;
