import { updateAxiosToken } from '@hooks/useAxios';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Context } from '@store/store';

interface AuthData {
  isLoggedIn: boolean;
  hasPartnershipOrdering: boolean;
  onLogin: (redirectUrl?: string) => void;
  onLogout: () => void;
  onInvalidAuth: () => void;
}

const useAuth = (): AuthData => {
  const [state] = useContext(Context);
  const navigate = useNavigate();

  const accessToken = state.keycloak?.token;
  updateAxiosToken(accessToken);
  const isLoggedIn = useMemo(
    () => !!state.keycloak?.authenticated && !!accessToken,
    [state.keycloak?.authenticated, accessToken],
  );

  const onLogout = () => {
    if (state.keycloak) {
      state.keycloak!.logout({ redirectUri: window.location.origin + '/login' });
    } else {
      navigate('/login');
    }
  };

  const onLogin = (redirectUrl = '/requisitions') => {
    state.keycloak?.login({ redirectUri: window.location.origin + redirectUrl });
  };

  const onInvalidAuth = useCallback(() => {
    navigate('/error', {
      state: {
        errorCode: 401,
      },
    });
  }, [navigate]);

  const hasPartnershipOrdering = useMemo(
    () => isLoggedIn && Array.isArray(state.currentClinician?.campaigns) && !!state.currentClinician?.campaigns.length,
    [state.currentClinician?.campaigns, isLoggedIn],
  );

  return {
    isLoggedIn,
    hasPartnershipOrdering,
    onLogin,
    onLogout,
    onInvalidAuth,
  };
};

export default useAuth;
