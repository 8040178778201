import { HTMLProps, useMemo, useState } from 'react';

import { Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';

import useBreakpoint, { SIZE_XS, SIZE_SM } from '@hooks/useBreakpoint';
import './ExpandableSection.scss';

interface IProps extends HTMLProps<HTMLElement> {
  children: React.ReactNode;
  headerTitle: React.ReactNode;
  defaultIsCollapsed?: boolean;
  expandableContentId: string;
}

const ExpandableSection = ({
  children,
  headerTitle,
  defaultIsCollapsed = false,
  expandableContentId,
  ...props
}: IProps) => {
  const [open, setOpen] = useState<boolean>(!defaultIsCollapsed);

  const breakpoint = useBreakpoint();
  const isWidthSMdown = useMemo(() => breakpoint === SIZE_XS || breakpoint === SIZE_SM, [breakpoint]);
  const buttonSize = isWidthSMdown ? 'sm' : undefined;
  const iconSize = isWidthSMdown ? 16 : 20;

  return (
    <section className='box-shadow-50 bg-white expandable-section' {...props}>
      <div className='d-flex align-items-center section-header'>
        <Button
          variant='ghost'
          size={buttonSize}
          aria-label={open ? 'Close section' : 'Open section'}
          aria-expanded={open}
          aria-controls={expandableContentId}
          onClick={() => setOpen(open => !open)}
        >
          <FontAwesomeIcon icon={(open ? faChevronUp : faChevronDown) as IconDefinition} fontSize={iconSize} />
        </Button>
        <h2 className='mb-0 section-header-title'>{headerTitle}</h2>
      </div>
      <Collapse in={open} timeout={0}>
        <div id={expandableContentId} className='d-flex flex-column gap-4 expandable-content'>
          {children}
        </div>
      </Collapse>
    </section>
  );
};

export default ExpandableSection;
