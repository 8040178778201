import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import { ResultsType } from '@customTypes/app';
import { OrderStatus } from '@customTypes/order';

export const KEYCLOAK_URL = 'https://auth.myome.com';
export const KEYCLOAK_CLIENT_ID = 'clinician-portal';
export const SENTRY_DSN =
  'https://a29e8189dba8d0057153cff2bdb4c862@o4506186993238016.ingest.sentry.io/4506753987575808';

export const MYOME_HOMEPAGE = 'https://myome.com';

export const SUPPORT_EMAIL = 'support@myome.com';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const environments: {
  [key: string]: {
    baseUrlRegex: RegExp;
    apiurl: string;
    keycloak_realm: string;
    sentry_enabled: boolean;
    googleMapsApiKey: string;
  };
} = {
  tst: {
    baseUrlRegex: /^https:\/\/(clinicians|providers).tst.myome.info(\/.*)?$/,
    apiurl: 'https://api.tst.myome.info/0',
    keycloak_realm: 'tst',
    sentry_enabled: false,
    googleMapsApiKey: 'AIzaSyD0B_jmB39u-gRWewG4L3Fu_abu2kOY_1c',
  },
  sbx: {
    baseUrlRegex: /^https:\/\/(clinicians|providers).sbx.myome.com(\/.*)?$/,
    apiurl: 'https://api.sbx.myome.com/0',
    keycloak_realm: 'sbx',
    sentry_enabled: true,
    googleMapsApiKey: 'AIzaSyD0B_jmB39u-gRWewG4L3Fu_abu2kOY_1c',
  },
  stg: {
    baseUrlRegex: /^https:\/\/(clinicians|providers).stg.myome.info(\/.*)?$/,
    apiurl: 'https://api.stg.myome.info/0',
    keycloak_realm: 'stg',
    sentry_enabled: true,
    googleMapsApiKey: 'AIzaSyD0B_jmB39u-gRWewG4L3Fu_abu2kOY_1c',
  },
  prd: {
    baseUrlRegex: /^https:\/\/(clinicians|providers).myome.com(\/.*)?$/,
    apiurl: 'https://api.myome.com/0',
    keycloak_realm: 'prd',
    sentry_enabled: true,
    googleMapsApiKey: 'AIzaSyD0B_jmB39u-gRWewG4L3Fu_abu2kOY_1c',
  },
  dev: {
    baseUrlRegex: /^https:\/\/(clinicians|providers).dev.myome.info(\/.*)?$/,
    apiurl: 'https://api.dev.myome.info/0',
    keycloak_realm: 'dev',
    sentry_enabled: true,
    googleMapsApiKey: 'AIzaSyCDR9y7bIuzn2VXdYy_vaQf1VAZmChEmEs',
  },
  local: {
    baseUrlRegex: /^http:\/\/localhost.*$/,
    apiurl: process.env.REACT_APP_API_URL || 'https://api.dev.myome.info/0',
    keycloak_realm: process.env.REACT_APP_KEYCLOAK_REALM || 'dev',
    sentry_enabled: false,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || 'AIzaSyCDR9y7bIuzn2VXdYy_vaQf1VAZmChEmEs',
  },
  vercel: {
    baseUrlRegex: /^(https:\/\/(clinicians|providers)-portal-.*-myome.vercel.app|.*.vcl.myome.com)(\/.*)?$/,
    apiurl: 'https://api.stg.myome.info/0',
    keycloak_realm: 'stg',
    sentry_enabled: true,
    googleMapsApiKey: 'AIzaSyCDR9y7bIuzn2VXdYy_vaQf1VAZmChEmEs',
  },
};

// Default to "dev" if no match
export let envInfo = environments.dev;

for (const envKey in environments) {
  const env = environments[envKey];
  if (env.baseUrlRegex.exec(window.location.href)) {
    envInfo = env;
    break;
  }
}

export const ORDER_STATUS_MAPPING: {
  [key in OrderStatus]: {
    weight: number;
    label: string;
  };
} = {
  CANCELED: {
    weight: 0,
    label: 'Canceled',
  },
  DENIED: {
    weight: 1,
    label: 'Denied',
  },
  FAILED: {
    weight: 2,
    label: 'Failed',
  },
  SUBMITTED: {
    weight: 3,
    label: 'Processing',
  },
  APPROVED: {
    weight: 4,
    label: 'Processing',
  },
  AWAITING_SAMPLE: {
    weight: 5,
    label: 'Awaiting Sample',
  },
  ANALYZING: {
    weight: 6,
    label: 'Analyzing',
  },
  CLINICIAN_REVIEW: {
    weight: 7,
    label: 'Clinician Review',
  },
  COMPLETED: {
    weight: 8,
    label: 'Reported',
  },
  REPORTED: {
    weight: 9,
    label: 'Reported',
  },
};

export const STATUS_ICON_MAPPING: { icon: IconDefinition; status: ResultsType }[] = [
  { icon: faDiamondExclamation as IconDefinition, status: ResultsType.ACTIONABLE_RESULT },
  { icon: faCircle as IconDefinition, status: ResultsType.AVERAGE_RISK_OR_NEGATIVE },
];

export const ROLE_PLACEHOLDER = 'Select a role';

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
