import Keycloak from 'keycloak-js';

import { Clinician } from '@customTypes/clinician';
import { FormattedProduct } from '@customTypes/product';
import { Requisition } from '@customTypes/requisition';
import { TaskFormInterface } from '@pages/requisitionCreate/components/JSONSchemaForm/JSONSchemaForm';

export interface AppState {
  requisitions?: Requisition[];
  isFetchingClinician?: boolean;
  currentClinician?: Clinician;
  keycloak?: Keycloak;
  newRequisition?: Requisition | null;
  formattedProductsList: FormattedProduct[];
  requisitionTaskForm?: TaskFormInterface;
}

type SetRequisitions = { type: 'SET_REQUISITIONS'; payload: Requisition[] };
type SetNewRequisition = { type: 'SET_NEW_REQUISITION'; payload: Requisition | null };
type SetCurrentClinician = { type: 'SET_CURRENT_CLINICIAN'; payload: Clinician };
type SetKeycloak = { type: 'SET_KEYCLOAK'; payload: Keycloak };
type SetFormattedProducts = { type: 'SET_FORMATTED_PRODUCTS_LIST'; payload: FormattedProduct[] };
type SetClinicianFetching = { type: 'SET_CLINICIAN_FETCHING'; payload: boolean };
type SetRequisitionTaskForm = { type: 'SET_REQUISITION_TASK_FORM'; payload: TaskFormInterface | undefined };

export type AppActions =
  | SetRequisitions
  | SetCurrentClinician
  | SetKeycloak
  | SetFormattedProducts
  | SetNewRequisition
  | SetClinicianFetching
  | SetRequisitionTaskForm;

export enum ResultsType {
  ACTIONABLE_RESULT = 'Actionable Result',
  AVERAGE_RISK_OR_NEGATIVE = 'Average risk or negative',
  NO_DATA_AVAILABLE = 'No data available',
}
