import { Nav } from 'react-bootstrap';

import Placeholder from '@components/Placeholder/Placeholder';
import useAuth from '@hooks/useAuth';

import './PatientDetailSidebar.scss';

interface IProps {
  numOfOrders: number;
  numOfDocuments: number;
  loading: boolean;
}

const PatientDetailSidebar = ({ numOfOrders, numOfDocuments, loading }: IProps) => {
  const { hasPartnershipOrdering } = useAuth();

  const handleSidebarClick = (sectionId: string) => {
    document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div
      // eslint-disable-next-line max-len
      className={`d-flex flex-row flex-lg-column patient-detail-sidebar ${hasPartnershipOrdering && 'partnership-ordering'}`}
    >
      <Nav as='nav' aria-label='On this page'>
        <ul className='d-flex flex-row flex-lg-column ps-0 mb-0 text-start sections-navbar'>
          {!loading && (
            <>
              <li key='orders'>
                <Nav.Link className='align-self-start' eventKey='orders' onClick={() => handleSidebarClick('orders')}>
                  Orders ({numOfOrders})
                </Nav.Link>
              </li>
              <li key='documents'>
                <Nav.Link
                  className='align-self-start'
                  eventKey='documents'
                  onClick={() => handleSidebarClick('documents')}
                >
                  Documents ({numOfDocuments})
                </Nav.Link>
              </li>
            </>
          )}

          {loading && (
            <>
              {Array.from(Array(3)).map((_, index) => (
                <li key={`menu-item-${index}`}>
                  <div className='px-3 py-2 sidebar-item-placeholder'>
                    <Placeholder size='lg' className='w-100' />
                  </div>
                </li>
              ))}
            </>
          )}
        </ul>
      </Nav>
    </div>
  );
};

export default PatientDetailSidebar;
