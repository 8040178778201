import { startCase } from 'lodash';

import { Clinician } from '@customTypes/clinician';

export const getClinicianName = (clinician?: Clinician | null) => {
  const name = clinician?.name;

  if (!name) return '';

  const cred = name.credentials;
  let formatted = startCase(`${name.first} ${name.middle ? name.middle + ' ' : ''}${name.last}`);

  if (cred) formatted += ', ' + cred;

  return formatted;
};
