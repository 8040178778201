import { AxiosRequestConfig } from 'axios';

import { Clinician } from '@customTypes/clinician';
import { OrderResult } from '@customTypes/order';
import { IPatientDetailRes } from '@customTypes/patient';
import { Requisition } from '@customTypes/requisition';
import { ISignupForm } from '@customTypes/user';
import axios from '@hooks/useAxios';

export const fetchRequisitions = async (): Promise<Requisition[]> => {
  return axios.get('/requisition', { params: { per_page: '500' } }).then(res => res.data.results);
};

// TODO: replace the unknown with an actual type.
export const placeOrder = async (payload: unknown) => {
  return axios.post('/requisition', payload);
};

export const getOrderResultsById = async (orderId: string): Promise<OrderResult[]> => {
  return axios.get(`/order/${orderId}`).then(res => res.data.results);
};

export const fetchLoggedInUserId = async (): Promise<string> => {
  return axios.get('/profile').then(res => res.data.identifiers.clinician_id);
};

export const fetchCurrentClinician = async (id: string): Promise<Clinician> => {
  return axios.get(`/clinician/${id}`).then(res => res.data);
};

export const fetchAllClinicians = async (): Promise<Clinician[]> => {
  return axios.get('/clinician').then(res => res.data.results);
};

export const fetchProductsList = async () => {
  return axios.get('/product').then(res => res.data);
};

export const fetchBaseRequisitionJSONSchema = async (
  productIds: string[],
  config: AxiosRequestConfig = {},
  campaignCode?: string,
) => {
  const params: Record<string, string> = {
    product_ids: productIds.join(','),
  };

  if (campaignCode) {
    params.campaign_code = campaignCode;
  }

  return axios.get('/provider/forms/requisition', { ...config, params }).then(res => res.data);
};

export const submitRequisitionJSONSchemaForm = async (data: unknown): Promise<Requisition> => {
  return axios.post('/provider/forms/requisition', data).then(res => res.data);
};

export const getPatientDetail = async (patientId: string): Promise<IPatientDetailRes> => {
  return axios.get(`/patient/${patientId}`).then(res => res.data);
};

export const postOnboarding = async (data: ISignupForm): Promise<string> => {
  // Remove id field from teammates because it's not acceptable in the payload
  return axios
    .post('/provider/onboarding', {
      ...data,
      teammates: data.teammates.map(mate => {
        delete mate.id;

        return mate;
      }),
    })
    .then(res => res.data);
};
