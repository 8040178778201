import React, { useMemo } from 'react';

import { Container, Stack } from 'react-bootstrap';

import './Footer.scss';

const links: Array<{ text: string; url: string }> = [
  {
    text: 'HIPAA Notice of Privacy Practices',
    url: 'https://myome.com/legal/hipaa-notice-of-privacy-practices',
  },
  {
    text: 'Terms of Service',
    url: 'https://myome.com/legal/terms-of-service',
  },
  {
    text: 'Privacy Notice',
    url: 'https://myome.com/legal/privacy-notice',
  },
];

const Footer = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className='bg-white border-cyan border-top pt-3 pb-4'>
      <Container fluid='xxl'>
        <Stack gap={3} className='text-second text-start'>
          <ul
            className='d-flex flex-wrap gap-2 list-group-numbered ps-0 mb-0'
            role='navigation'
            aria-label='footer nav'
          >
            {links.map((link, index) => (
              <React.Fragment key={link.url}>
                <li>
                  <a
                    href={link.url}
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex fs-body fw-medium text-decoration-none'
                  >
                    {link.text}
                  </a>
                </li>
                {index !== links.length - 1 && <div className='fs-body text-second'>|</div>}
              </React.Fragment>
            ))}
          </ul>
          <div className='d-flex flex-column'>
            <p className='fs-body mb-0'>© {currentYear} MyOme.com</p>
            <p className='fs-body small mb-0'>
              MyOme is not responsible for the content or accuracy of third-party websites
            </p>
          </div>
          <p className='mb-0 fs-body small'>
            The MyOme Personal Genome Report was developed, and its performance characteristics were determined, by
            MyOme, Inc., a clinical laboratory certified under the Clinical Laboratory Improvement Amendments of 1988
            (CLIA) to perform high complexity clinical laboratory testing. This test has not been cleared or approved by
            the U.S. Food and Drug Administration (FDA). The FDA has determined that such clearance or approval is not
            necessary for laboratory-developed tests.
          </p>
        </Stack>
      </Container>
    </footer>
  );
};

export default Footer;
